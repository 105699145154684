.datatable__area .column-action {
	width: 100%;
	justify-content: right;
}

.hkMDrI {
	min-height: 300px !important;
}

.eSVLyH {
	min-height: 300px !important;
}

.flex-grow-1 p {
	font-weight: 600;
	margin: 0;
}

.masg_box.flex-grow-1 {
	padding-bottom: 10px;
}

.masg_box {
	border-bottom: 1px solid #2222;
	padding-top: 20px;
}

.hkMDrI__2_fuLPYh .hkMDrI,
.hkMDrI__2_fuLPYh .fuLPYh {
	overflow-x: initial !important;
	overflow-y: initial !important;
}

.masg_box:last-child {
	border-bottom: none;
}

.react-dataTable .rdt_TableHead .rdt_TableHeadRow {
	background-color: #f8f7ff !important;
}

.cdemng {
	display: block;
	float: left !important;
	width: 100% !important;
	padding: 16px;
	background-color: #dad8ef;
}

.message_send_box {
	width: 100%;
	float: left;
	display: block;
}

.message_send_box .form-label {
	width: 100%;
	float: left;
	display: block;
}

.message_send_box {
	width: 100%;
}

textarea,
textarea:focus-visible {
	border: 1px solid #222;
	box-shadow: none !important;
}

.massage_from_area button {
	margin-top: 15px;
	width: 100%;
	padding: 11px;
	border: none;
}

.massage_from_area textarea {
	padding: 0.571rem 1rem;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #d8d6de;
	border-radius: 0.357rem;
	width: 100%;
}

.massage_from_area label {
	margin-bottom: 10px;
}

.message__box__scroll {
	max-height: 300px;
	overflow-y: scroll;
	width: 100%;
	overflow-x: hidden;
	margin-bottom: 13px;
	padding: 15px;
}

.comfrim_btn {
	background: transparent;
	border: none;
	color: #fff;
	font-size: 12px;
	font-weight: 600;
}

.nav-pills .nav-link {
	padding: 0.786rem 1rem !important;
	font-size: 13px;
}

.from_area__order_details {
	width: 100%;
	float: left;
	display: block;
	margin-top: 2px;
	margin-bottom: 11px;
}

.massage_from_order_area p {
	color: #f00;
}

.massage_from_order_area button {
	width: auto !important;
	padding: 10px 30px !important;
	font-weight: 600;
}

.my_table_style {
	width: 100%;
	float: left;
	display: block;
}

.my_table_style td,
.my_table_style th {
	width: 100px;
}


.my_table_style table {
	table-layout: fixed;
	width: 100%;
}

.my_table_style td:first-child,
.my_table_style th:first-child {
	position: sticky;
	left: 0;
	z-index: 1;
}

.my_table_style thead tr th {
	position: sticky;
	top: 0;
}

.my_table_style th:first-child,
.my_table_style th:last-child {
	z-index: 2;
}

.nodata_tbl_cls {
	width: 1366px;
	text-align: center;
	padding: 30px 0 0 0;
	margin: 0;
	font-weight: 600;
}

.font_icon_color svg {
	color: #f00;
}

.font_icon_color:last-child svg {
	color: #28c76f;
}

.font_icon_color span {
	color: #28c76f;
}

.MuiTableRow-root:nth-child(even) {
	background-color: #e4e2f3 !important;
}

.MuiTableCell-head {
	background-color: #b7b0ff !important;
	color: #000 !important;
	font-weight: bold !important;
	padding: 8px 15px !important;
}

.MuiTableCell-root {
	padding: 5px !important;
	line-height: 1;
	border: 1px solid gray;
}
#p-cus .MuiTableCell-root {
	padding: 2px 5px !important;
	border: 1px solid gray;
}

.MuiTablePagination-selectLabel {
	margin-bottom: 0px !important;
	word-break: break-all !important;
}

.MuiTablePagination-displayedRows {
	margin-bottom: 0px !important;
}



.rdt_TableRow:nth-child(odd):hover {
	background-color: #e2def5ab !important;
}

.MuiTablePagination-displayedRows {
	margin-bottom: 0px !important;
}

.font__size__ad {
	width: 100%;
	text-align: center;
	color: #222 !important;
	font-weight: 600;
}

.new_myCLass {
	width: 100%;
	border: 2px solid #f00;
	padding: 46px 0;
	text-align: center;
	font-weight: 600;
	background: #fff;
}

.details_table_btn button {
	float: left;
	padding: 5px !important;
	margin: 0 3px 0 0 !important;
}

.my_table_style_new .table thead th,
.table tfoot th {
	text-transform: capitalize !important;
}

.my_table_style_new .table:not(.table-dark):not(.table-light) thead:not(.table-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.table-dark) th {
	background-color: #b2acf7 !important;
	color: #222 !important;
	font-size: 13px !important;
	padding: 15px 12px !important;
}

.my_table_style_new .table> :not(caption)>*>* {
	padding: .92rem 1rem !important;
	text-transform: capitalize;
}

.loader2 {
	position: initial;
}

.outer,
.middle,
.inner {
	border: 3px solid transparent;
	border-top-color: #ffffff;
	border-right-color: #ffffff;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
}

.outer {
	width: 4em;
	height: 4em;
	margin-left: -1.75em;
	margin-top: -1.75em;
	animation: spin 2s linear infinite;
}

.middle {
	width: 2.5em;
	height: 2.5em;
	margin-left: -1.05em;
	margin-top: -1.05em;
	animation: spin 1.75s linear reverse infinite;
}

.inner {
	width: 1em;
	height: 1em;
	margin-left: -0.4em;
	margin-top: -0.4em;
	animation: spin 1.5s linear infinite;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}

.order_details_style {
	width: 100%;
	float: left;
	display: block;
}

.order_details_style h5 {
	width: auto;
	float: left;
	margin-right: 15px;
	font-weight: 600;
}

.preOrder_tarih_area span {
	width: 100%;
	display: block;
	padding-bottom: 8px;
}

.preOrder_tarih_area input {
	width: 70%;
	float: left;
	display: block;
}


.preOrder_tarih_area button {
	float: right;
	background: #28c76f;
	border: navajowhite;
	padding: 8px 30px;
	color: #fff;
	font-weight: 600;
	border-radius: 5px;
}

.preOrder_tarih_area button:hover {
	color: #222;
}

#detailstable td {
	padding: 10px 10px;
}

.MuiTableCell-head {
	z-index: 0 !important;
}

.MuiTableCell {
	z-index: -999 !important;
}

.bin_No_backgroung {
	background: transparent;
	border: none;
	color: #6e6b7b;
	font-weight: 600;
}

.cardBin__model_area .css-9syamw {
	padding: 0px !important;
}

.cardBin__model_area_h4 {
	text-align: center;
	margin-bottom: 20px;
	width: 100%;
	background: #f6f6f6;
	padding: 15px 0;
	font-size: 15px;
}

.cardBin__model_area_body {
	padding: 2px 30px;
}

.cardBin__model_area_body p {
	float: left;
}

.table_dataNotFOund {
	width: 100%;
	display: table-caption;
	text-align: center;
	padding: 20px;
	font-weight: 600;
	color: #f00;
}

.invoice-list-wrapper .react-dataTable .rdt_TableHead .rdt_TableHeadRow {
	min-height: 0;
	height: 65px;
}

.invoice-list-wrapper .lnndaO {
	white-space: initial !important;
	text-transform: capitalize !important;
}

// .animate__animated .btn {
// 	padding: 0.786rem 0.5rem !important;
// }

.user_quality_deign {
	width: 100%;
	float: left;
	display: block;
}

.user_quality_deign h5 {
	float: left;
}

.user_quality_deign span {
	float: left;
	padding-left: 15px;
}

.animate__animated .content-body .css-bhp9pd-MuiPaper-root-MuiCard-root,
.animate__animated .content-body .css-s18byi {
	overflow: inherit !important;
}

.currencybox {
	width: 100px;
	padding: 10px;
	color: #fff;
	display: inline-block;
	margin: 10px;
	margin-bottom: 20px;
	border-radius: 5px;
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
	// border: 1px solid gray !important;
	border: 1px solid #ececec !important;
	border-bottom-width: 0px !important;
}

.react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
	border: 1px solid #ececec !important;
	border-bottom-width: 0px !important;
}

// .swal2-container {
// 	z-index: 99999 !important;
// }

.cheack__box {
	width: 15px;
	height: 15px;
	margin-right: 11px;
	margin-top: 3px;
}

#f-row .row {
	margin-bottom: 8px !important;
}


#f-row textarea {
	background: url('../../../assets/images/number.png');
	background-attachment: local;
	background-repeat: no-repeat;
	padding-left: 35px !important;
	padding-top: 10px !important;
	padding-bottom: 20px !important;
	border-color: #ccc;
	font-size: 13px;
	line-height: 16px;
}

#f-row .textarea-wrapper {
	display: inline-block;
	background-image: linear-gradient(#F1F1F1 50%, #F9F9F9 50%);
	background-size: 100% 32px;
	background-position: left 10px;
	width: 100%;
}

#f-row .form-control {
	padding: 0.4rem 1rem;
}

// #f-row textarea {
// 	background: url('../../../assets/images/number.png');
// 	background-attachment: local;
// 	background-repeat: no-repeat;
// 	padding-left: 35px !important;
// 	padding-top: 10px !important;
// 	padding-bottom: 20px !important;
// 	border-color: #ccc;
// 	font-size: 13px;
// 	line-height: 16px;
// }
// #f-row .textarea-wrapper {
//   display: inline-block;
//   background-image: linear-gradient(#F1F1F1 50%, #F9F9F9 50%);
//   background-size: 100% 32px;
//   background-position: left 10px;
//   width: 100%;
// }
// #f-row .form-control {
// 	padding: 0.4rem 1rem;
// }
.editor {
	counter-reset: line;
	border: 1px solid #ced4da;
	min-height: 100px !important;
}

.editor #codeArea {
	outline: none;
	padding-left: 100px !important;
	padding-top: 0px !important;
	min-height: 100px !important;
}

.editor pre {
	padding-left: 100px !important;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	min-height: 100px !important;
}

.editor .editorLineNumber {
	position: absolute;
	// left: 0px;
	color: #191414 !important;
	text-align: center !important;
	width: 60px !important;
	// padding-right: 10px !important;
	font-weight: 100;
	background-color: #f0efef;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
	min-height: 100px !important;
}

.text_color {
	color: #222 !important;
}

.animate__animated .bsgpsK div:first-child {
	white-space: inherit !important;
}

.bankmanage_table_header {
	width: 100%;
	float: left;
	display: block;
	background: #7367f0;
	padding: 10px 8px;
}

.bankmanage_table_header span {
	font-size: 17px;
	color: #fff;
	font-weight: 600;
	// padding-top: 10px;
	float: left;
}

.bankmanage_table_header button {
	float: right;
	background: #339933;
	border: none;
	padding: 3px 15px;
	color: #fff;
	font-weight: 600;
	border-radius: 5px;
	font-size: 14px;
}

.add_money_trsfer_h_txt {
	width: 100%;
	display: block;
	float: left;
	background: #393377;
	padding: 8px 15px;
	border-radius: 5px;
	font-size: 16px;
	color: #fff;
	border-radius: 5px;
}

.add_money_trsfer_h_txt span {
	float: right;
	cursor: pointer;
}

.add_money_trsfer_h_txt span:hover {
	color: #7367f0;
}

.add_money_trsfer_h_txt_input {
	width: 100%;
	height: 37px;
	border: 1px solid hsl(0, 0%, 80%);
	border-radius: 3px;
	padding: 0 15px;
}

.from_home_area label {
	margin-bottom: 5px;
	font-weight: 600;
}

.from_home_area_submit {
	background: #383276;
	border: none;
	color: #fff;
	padding: 9px 51px;
	border-radius: 3px;
	font-weight: 600;
}

.ant-form-item-explain-error {
	color: #f00;
	font-size: 12px;
	font-weight: 600;
	margin-top: 4px;
}

.textarea___imput {
	width: 100%;
	border: 1px solid #2222 !important;
	border-radius: 3px;
}

::-webkit-scrollbar {
	width: 12px;
}

::-webkit-scrollbar-track {
	background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background-color: rgb(158, 157, 157);
}

.ant-form-item {
	margin-bottom: 0px !important;
}

.ant-input-number {
	width: 100% !important;
}

.textarea___imput {
	height: 38px !important;
}

.delect__box {
	margin-top: -7px;
	border-radius: 40px;
	padding: 0;
	background: #f00 !important;
	width: 30px;
	height: 30px;
}

.delect__box svg {
	height: 16px !important;
	width: 15px !important;
	font-size: 9px !important;
}

.m_t_30 {
	margin-top: 25px !important;
}

.w_15 {
	width: 20% !important;
	text-align: center;
	float: right;
	line-height: 35px;
	margin-top: 31px;
	margin-bottom: 0 !important;
}

.w_82 {
	width: 40% !important;
	float: left;
	display: block;
}

.w_25 {
	width: 20% !important;
	float: left;
	display: block;
	margin-top: 31px !important;
	margin-left: 8px !important;
}

.p_class {
	width: auto;
	float: left;
	margin-top: 36px;
	font-size: 16px;
	margin-left: 9px;
}

.eCkmfb {
	background-color: #F2F2F2 !important;
}

// .rdt_TableCell {
// 	font-weight: 600 !important;
// 	color: #222 !important;
// }

.pading__m {
	background: #04426f;
	width: 30px !important;
	height: 30px !important;
	padding: 7px !important;
	margin-top: -4px !important;
	border-radius: 50px !important;
	margin-right: 1px !important;
	cursor: pointer;
}

.pading__X {
	background: #f00 !important;
	margin-left: 5px;
}

.datatable__area {
	position: relative !important;
}

.total_a_incoming_money {
	width: 100%;
	display: inline-block;
	font-weight: 600;
	font-size: 14px;
	position: absolute;
	bottom: 65px;
	text-align: right;
	// right: 30px;
	padding-right: 22px;
	margin: 0;
}

.total_a_incoming_money span {
	color: #f00;
	font-weight: 600;
}

.incoming__mony__table .bhoXfZ,
.incoming__mony__table .eaGqxa,
.incoming__mony__table .iFRVZk {
	margin-top: 50px !important;
}

.datatable__area nav {
	margin-top: 50px !important;
}

.payment_list_details_box {
	width: 100%;
	display: block;
	display: flex;
	background: #2222;
	padding: 10px 10px;
	margin-bottom: 15px;
}

.payment_list_details_box p {
	margin: 0;
	width: 200px;
	padding-left: 10px;
	margin-bottom: 5px !important;
	padding-left: 10px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-weight: 600;
}

.payment_list_details_box span {
	line-height: 20px;
}

.datatable__area .rdt_TableCell {
	height: 32px !important;
}

.datatable__area .eCkmfb,
.datatable__area .fhCFWO {
	min-height: 34px;
}

.datatable__area .tss-5jmwmz-MUIDataTableFooter-root {
	display: table;
	width: 100%;
	border-collapse: collapse;
	border-spacing: 0;
	margin-top: 35px;
}

.datatable__area .MuiTableCell-head {
	background-color: #fcffe8 !important;
	padding: 0px 5px !important;
}

.datatable__area .MuiTableRow-root:nth-child(2n) {
	background-color: #efefef !important;
}

.datatable__area .tss-tlx3x1-MUIDataTableToolbar-root {
	background: #7367f0;
	color: #fff;
}

.datatable__area .MuiTypography-h6 {
	color: #fff;
	font-weight: 600;
	margin: 0;
}

.client__data_table .rdt_TableCell {
	height: 40px !important;
}

.client__data_table .eCkmfb,
.client__data_table .fhCFWO {
	min-height: 40px !important;
}

.disabled__ svg {
	animation-name: spin;
	animation-duration: 2s;
	animation-delay: 0s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	// margin-right: 5px;
	padding: 4px;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes appear {
	100% {
		opacity: 1;
	}
}

.payment_list_details_box__btn {
	background: #ea5455;
	color: #fff;
	border: none;
	padding: 5px 20px;
	border-radius: 5px;
	font-weight: 600;
	transition: all .3s;
}

.payment_list_details_box__btn:hover {
	background: #b73838;
}

.swal2-container {
	z-index: 9999 !important;
}

.green {
	background: #436904 !important;
}

[dir] .Toastify__toast {
	margin-bottom: 1rem;
	padding: 35px 20px;
	border-radius: 4px;
	box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
	cursor: pointer;
	width: 400px;
	min-height: 80px;
}

.userInformation____area {
	margin-top: 10px;
	text-align: center;
}

.userInformation____area p {
	background: #1e74c5;
	text-align: center;
	padding: 5px 0;
	color: #fff;
	font-weight: 600;
	margin: 0;
}

.userInformation____area h3 {
	background: #368ee0;
	text-align: center;
	padding: 15px 0;
	color: #fff;
	font-weight: 600;
	font-size: 14px;
	margin: 0;
}

.userInformation____area h5 {
	background: #2222;
	padding: 10px 0;
	margin: 0px;
	border-bottom: 1px solid #2222;
	font-size: 12px;
	overflow-y: hidden;
}

.yes__changes_btn {
	background: #ea5455;
	border: none;
	padding: 5px 10px;
	color: #fff;
	font-size: 13px;
	border-radius: 5px;
}

.usermsg___cntn {
	width: 100%;
	float: left;
	display: block;
	padding: 15px;
	border-bottom: 1px solid #2222;
	text-align: left;
	position: relative;
}

.usermsg___area {
	width: 100%;
	display: block;
	float: left;
	height: 240px;
	overflow-y: scroll;
	margin-top: 10px;
}

.usermsg___cntn span {
	float: left;
	width: 100%;
	// line-height: 35px;
	padding-top: 10px;
	width: 75%;
}

.usermsg___cntn h5 {
	float: left;
	background: transparent;
	border: none;
	position: absolute;
	top: 7px;
	right: 15px;
}

.usermsg___cntn h4 {
	float: left;
	background: transparent;
	border: none;
	position: absolute;
	bottom: 7px;
	right: 15px;
	font-size: 13px;
}

.bottom__Btn {
	background: #0d5210;
	padding: 0 15px;
	border: none;
	border-radius: 3px;
	color: #fff;
	margin-top: 10px;
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell .band_bank_management {
	height: 40px !important;
}

.band_bank_management .rdt_TableCell {
	height: 22px !important;
}

.payment_methods_management_table .MuiTableCell-root {
	// padding: 10px 5px !important;
	border: 1px solid #cdcdcd !important;
	text-transform: capitalize !important;
}

.payment_methods_management_table .MuiTableCell-head {
	background-color: #8e85f3 !important;
	padding: 8px 15px !important;
	color: #fff !important;
}

.payment_methods_management_table .MuiTableRow-root:nth-child(2n) {
	background-color: #95959521 !important;
}

.p_details_datatable__area .rdt_TableCell {
	height: 24px !important;
}

.ant-input:focus,
.ant-input-focused {
	/* border-color: #40a9ff; */
	/* box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); */
	border-color: #7367f0 !important;
	box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1) !important;
}

.from_home_area_ppp .ant-input {
	border-radius: 3px !important;
}

.from_home_area_ppp .ant-form-item-label {
	text-align: left !important;
}

.from_home_area_ppp .ant-form-item {
	margin-bottom: '15px' !important;
}

[dir] .from_group__ {
	margin-bottom: 15px !important;
	height: 40px;
}

#f-row .col-form-label {
	color: #161d31;
}

#f-row .css-1s2u09g-control {
	border-color: #283046;
}

[dir] #f-row .form-control {
	border: 1px solid #283046;
}

.band_bank_management .react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
	padding: .32rem .2rem;
}

.band_bank_management .eCkmfb,
.band_bank_management .fhCFWO,
.band_bank_management .iWtnjH,
.band_bank_management .cohCfu {
	min-height: 22px !important;
}

.bibo__product__details_tbl .MuiTableCell-root {
	padding: 0px 15px !important;
	border: 1px solid gray;
}

// .bibo__product__details_tbl .animate__animated .btn {
// 	padding: 0.366rem 0.5rem !important;
// }

.outgoing_table__list .MuiTableCell-root {
	padding: 0px 5px !important;
}

.incoming__mony__table_two .iWtnjH {
	min-height: 32px !important;
}

.payment_methods_management_table .MuiTableCell-root {
	padding: 3px 5px !important;
	border: 1px solid #cdcdcd !important;
}

.getbalanceClass {
	border: 1px solid #2222;
	padding: 10px 25px;
	border-radius: 4px;
	margin-top: 0;
	display: inline-table;
}

.editor {
	counter-reset: line;
	border: 1px solid #ced4da;
}

.editor #codeArea {
	outline: none;
	padding-left: 60px !important;
}

.editor pre {
	padding-left: 60px !important;
}

.editor .editorLineNumber {
	position: absolute;
	left: 0px;
	color: #cccccc;
	text-align: right;
	width: 40px;
	font-weight: 100;
}

.dlet__btn {
	background: #ea5455;
	border: none;
	padding: 1px;
	margin-right: 10px;
	border-radius: 25px;
}

.dlet__btn svg {
	color: #fff;
	padding: 4px;
}

input::placeholder {
	color: #6e6b7b !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
	padding: 1px !important;
}

.new__input__unumber {
	border: 1px solid #283046 !important;
	border-radius: 5px !important;
	height: 34px !important;
}

.css-10drtbx-MuiButtonBase-root-MuiCheckbox-root {
	padding: 0px !important;
}

.new__frm__class .from_group__ {
	height: 50px !important;
}

.css-1s2u09g-control {
	font-size: 14px;
	font-family: "Times New Roman";
}

[dir] .form-control {
	font-size: 14px;
	font-family: "Time New Roman";
}

.css-d57rwb-MuiFormLabel-root-MuiInputLabel-root {
	font-size: 14px;
	font-family: "Time New Roman";
	color: hsl(0, 0%, 20%);
}

#f-row .col-form-label {
	font-size: 13px;
	font-family: "Time New Roman";
	font-size: 14px;
	font-family: "sans-serif";
}

.css-1ymegwh-MuiFormLabel-root-MuiInputLabel-root {
	color: hsl(0, 0%, 20%) !important;
	font-family: "Time New Roman";
	font-size: 14px;
}

.currenci__box .from_group__ {
	height: 40px !important;
}

.border_none {
	border: none !important;
}

.pagination___area {
	width: 100%;
	float: left;
	display: block;
	padding: 15px;
}

.emty__data {
	width: 100%;
}

.muiTableCell_root___999 .MuiTableBody-root tr td:nth-child(1) {
	width: 68% !important;
}

.muiTableCell_root___999 .MuiTableBody-root tr td:nth-child(2) {
	width: 7% !important;
}

.muiTableCell_root___999 .MuiTableBody-root tr td:nth-child(3) {
	width: 13% !important;
}

// .muiTableCell_root___999 .MuiTableBody-root tr td:nth-child(4) {
// 	width: 10% !important;
// }


.totalDataShow {
	float: left;
	font-size: 14px;
	margin-left: 15px;
	border: 1px solid #2222;
	padding: 4px 10px;
}

.desbtn {
	width: 40px;
	display: inline-block;
	text-align: center;
	padding: 5px;
	cursor: pointer;
}
.swal2-cancel{
	margin-left: 10px;
}
.d-none {
	display: none;
}
.cusin {
    width: 48px;
    border: none;
    text-align: center;
    font-weight: bold;
}

#cusss .MuiTableCell-root  {
	padding: 0 14px !important;
  }
  input[type=number]::-webkit-outer-spin-button, 
  input[type=number]::-webkit-inner-spin-button {
	  -webkit-appearance: inner-spin-button !important;
	  opacity: 1;
  }
  .rdw-editor-main {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    border: 1px solid #F1F1F1 !important;
	padding: 5px !important;
}
#tbl .react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
    padding: 4px !important;
}

@media (min-width: 1900px){
.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 2500px;
}
}